// Constantes
import { ADDONS, MENUS_TYPES } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
import intercomMixin from '@/mixins/intercomMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import {
  createCategory,
  updateCategoryById,
  getCategoryById,
  deleteCategoryById,
  deleteEveryCategoriesAndDishesByCategoryId
} from '@/services/category'
import { deleteEveryDishesByCategoryId, setEveryPricesToNullByCategoryId } from '@/services/dish'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'CategoryForm',
  components: { FormButtons, VuetifyContentLoading },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin, intercomMixin],
  props: {
    id: {
      type: String,
      default: null
    },
    parentId: {
      type: String,
      default: null
    },
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestañas del componente
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Form
      formFields: {
        description: null,
        disabled: false,
        name: null,
        price: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        price: {
          required: 'Debes indicar un precio'
        }
      },
      // Others
      categoryData: null,
      currentId: this.id,
      isMenu: false,
      isTAD: false,
      parentCategoryData: null,
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData', 'currencyData']),
    ...mapGetters('intercom', ['intercomData']),
    /**
     * Currency Symbol
     *
     * @return {String}
     */
    currencySymbol() {
      return get(this.currencyData, 'symbol', null)
    },
    /**
     * Campos que son editables en el formulario
     *
     * @return {array | boolean} - listado de los campos editables
     */
    editableFields() {
      const owner = get(this.categoryData, 'owner', null)
      const editabled = get(this.categoryData, 'editabled', false)
      const fieldsCustomItem = get(this.categoryData, 'fieldsCustomItem', [])

      if (!isNil(owner)) {
        return editabled ? fieldsCustomItem : []
      }

      return true // todo es editable
    },
    /**
     * El formulario es editable?
     *
     * @return {boolean}
     */
    formIsEditable() {
      return (
        (Array.isArray(this.editableFields) && this.editableFields.length > 0) ||
        this.editableFields === true
      )
    },
    /**
     * Get the labels to show in the formulary
     *
     * @return {Object} - diffent labels
     */
    formLabels() {
      const labels = {
        labelButtonSave: 'Guardar',
        labelButtonCancel: 'Cerrar',
        labelButtonDelete: !this.parentId
          ? '¿Estas seguro que quieres eliminar la carta/menú?'
          : '¿Estas seguro que quieres eliminar la categoría?',
        labelNotAvailable: !this.parentId ? 'Carta/Menú no disponible' : 'Categoría no disponible',
        labelTypeCategory: 'categoría'
      }

      if (this.parentId) {
        labels.labelNotAvailable = 'Categoría no disponible'
        labels.labelTypeCategory = 'Categoría'
      } else if (!this.parentId && !this.isMenu) {
        labels.labelNotAvailable = 'Carta no disponible'
        labels.labelTypeCategory = 'Carta'
      } else if (!this.parentId && this.isMenu) {
        labels.labelNotAvailable = 'Menú no disponible'
        labels.labelTypeCategory = 'Menú'
      }

      return labels
    },
    /**
     * La categoría fue creada por una marca?
     *
     * @return {boolean}
     */
    isBrandCategory() {
      return !isNil(get(this.categoryData, 'owner', null))
    }
  },
  watch: {
    isMenu(newValue) {
      if (newValue === false) {
        this.formFields.price = null
      }
    }
  },
  async mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * When the user must click on delete button
     */
    async handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Removing item
          await this.deleteCategory()
          // Evento general para que cualquier lista
          // a la escucha, recargue su listado
          this.$root.$emit('reload-list')
          // Hide dialog
          this.hideDialog()
        },
        actionButtonText: 'Borrar',
        text: this.formLabels.labelButtonDelete,
        type: 'warning',
        visible: true
      })
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Modificamos el atributo "itemsData" del componente padre
     *
     * @param {Object} data - datos a emitir
     */
    changeItemsData(data) {
      this.$parent.$parent.$parent.$emit('onChangeItemsData', this.index, data)
    },
    /**
     * Esta función es llamada desde el padre (VuetifyTabs)
     * cuando el usuario pulsa sobre alguno de las pestañas
     *
     * @param {Number} tab - pestaña donde deseamos movernos
     */
    async fnToChangeTab(tab) {
      let result = false

      try {
        if (!this.currentId) {
          // Create categorie
          result = await this.onSubmit()
        } else {
          // Update categorie
          result = this.actionSubmit()
        }

        if (result) {
          this.$parent.$parent.$parent.$emit('onChangeTab', tab)
        }
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
      }
    },
    /**
     * Obtengo todos los datos necesarios para el formulario
     */
    async getEveryNeededData() {
      try {
        // Edición
        if (this.currentId) {
          // Loading
          this.processingRequest = true
          // Get category data
          this.categoryData = await this.getCategoryData(this.currentId)
          // Para que los datos estén disponibles para el resto de pestañas (VuetifyTabs)
          this.changeItemsData({
            data: this.categoryData
          })
          // Set form variables
          this.setFormFieldsValues(this.categoryData)
          // Es una categoría padre?
          if (isNil(this.categoryData.parentId)) {
            this.isMenu = Boolean(this.categoryData.price) && !this.parentId
            this.isTAD = this.categoryData.type === MENUS_TYPES.takeAway.value
          }
        }
        // ¿Tenemos una categoría padre (menú)?
        if (this.parentId) {
          this.parentCategoryData = await this.getCategoryData(this.parentId)
          this.isMenu = Boolean(this.parentCategoryData.price) && !this.parentId
          this.isTAD = this.parentCategoryData.type === MENUS_TYPES.takeAway.value
        }
      } catch (error) {
        // show error
        this.handleError(error.message)
        // hide dialog
        this.hideDialog()
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Get the data of current category
     *
     * @param {String} id - UID category
     * @return {Object}
     */
    async getCategoryData(id) {
      const categoryData = await getCategoryById(id)
      return categoryData
    },
    /**
     * Establecemos los valores iniciales de los
     * campos del formulario
     *
     * @param {object} data - objeto con los datos a establecer
     */
    setFormFieldsValues(data = {}) {
      // Datos generales
      this.formFields.name = get(data, 'name', this.formFields.name)
      this.formFields.description = get(data, 'description', this.formFields.description)
      this.formFields.disabled = get(data, 'disabled', this.formFields.disabled)
      this.formFields.price = get(data, 'price', this.formFields.price)
    },
    /**
     * Dado un campo del formulario, comprobamos si este puede
     * ser editado
     *
     * @param {string} name - nombre del campo
     * @return {boolean} - es editable?
     */
    isEditable(name = null) {
      return Array.isArray(this.editableFields)
        ? this.editableFields.indexOf(name) > -1
        : this.editableFields
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Update categorie in DB
      await this.saveCategory()
      // Evento general para que cualquier lista
      // a la escucha, recargue su listado
      this.$root.$emit('reload-list')
    },
    /**
     * Differents actions to update the category in
     * the database
     */
    async saveCategory() {
      // Configuraciones del establecimiento
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})

      if (this.currentId) {
        // Datos a salvar (Actualización)
        const dataToSave = {
          id: this.currentId,
          // Filtramos los campos editables
          ...(!Array.isArray(this.editableFields)
            ? this.formFields
            : this.editableFields.reduce((accFields, field) => {
                if (!isNil(this.formFields[field])) {
                  accFields[field] = this.formFields[field]
                }
                return accFields
              }, {}))
        }

        await updateCategoryById(dataToSave, {
          additionalLanguages: placeConfig.additionalLanguages,
          defaultLanguage: placeConfig.defaultLanguage
        })

        // Carta -> Menú, establecemos todos los precios a NULL
        if (isNil(this.parentId) && !isNil(this.formFields.price)) {
          const { ok } = await setEveryPricesToNullByCategoryId(this.currentId)
          if (!ok) {
            throw new Error('No se pudieron establecer los precios de los platos.')
          }
        }
      } else {
        // Creación
        const { category } = await createCategory(
          {
            ...this.formFields,
            ...(isNil(this.parentId)
              ? {
                  places: [this.placeData.id],
                  type: this.isTAD ? MENUS_TYPES.takeAway.value : MENUS_TYPES.place.value
                }
              : {}),
            parentId: this.parentId
          },
          null,
          {
            additionalLanguages: placeConfig.additionalLanguages,
            defaultLanguage: placeConfig.defaultLanguage
          }
        )

        // Establecemos el nuevo ID de la categoría
        this.currentId = category.id
        // Establecemos los nuevos datos de la categoría
        this.categoryData = category

        // Intercom
        const totalMenus = get(this.intercomData, 'menus', 0)
        const totalCategories = get(this.intercomData, 'categories', 0)

        isNil(this.parentId)
          ? this.intercomUpdate({
              bakarta_menus: totalMenus + 1
            })
          : this.intercomUpdate({
              bakarta_categorias: totalCategories + 1
            })
      }

      // Para que los datos estén disponibles para el resto de pestañas (VuetifyTabs)
      this.changeItemsData({
        data: this.categoryData
      })

      // Si es TAD, cerramos la modal
      if (this.isTAD) {
        this.hideDialog()
      }

      // Información resultados acción de salvado
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })

      // Emitimos evento general
      this.$root.$emit('completed-category-form', { id: this.currentId })
    },
    /**
     * Different actions to delete the categories
     * and every associated to it
     */
    async deleteCategory() {
      // Solo por seguridad.
      // Un establecimiento, no puede borrar los datos
      // de una categoría creada por la marca
      if (this.isBrandCategory) return

      try {
        // Loading
        this.formProcessing = true

        // Categoría padre (es un menú o carta)
        if (!this.categoryData.parentId) {
          // Eliminamos categorías hijas y platos
          await deleteEveryCategoriesAndDishesByCategoryId(this.categoryData.id)
          // Eliminamos categoría
          await deleteCategoryById(this.categoryData.id)
          // Intercom
          const totalMenus = get(this.intercomData, 'menus', 0)
          this.intercomUpdate({
            bakarta_menus: totalMenus > 0 ? totalMenus - 1 : 0
          })
        } else {
          // Eliminamos platos asociados
          const { ok } = await deleteEveryDishesByCategoryId(this.categoryData.id)

          if (!ok) {
            throw new Error('No se pudieron eliminar los platos asociados a la categoría.')
          }

          // Eliminamos categoría
          await deleteCategoryById(this.categoryData.id)
          // Intercom
          const totalCategories = get(this.intercomData, 'categories', 0)
          this.intercomUpdate({
            bakarta_categorias: totalCategories > 0 ? totalCategories - 1 : 0
          })
        }
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        // Loading
        this.formProcessing = true
      }
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  },
  // Validations with Vuelidate
  validations() {
    const rules = {
      formFields: {
        description: {},
        disabled: {},
        name: {
          required
        },
        price: {}
      }
    }

    if (this.isMenu) {
      rules.formFields.price = {
        required
      }
    }

    return rules
  }
}
