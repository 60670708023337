// Components
import CategoryForm from '@/components/elements/categories/CategoryForm'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import onboardingMixin from '@/mixins/onboardingMixin'
import uiMixin from '@/mixins/uiMixin'
import intercomMixin from '@/mixins/intercomMixin'
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'OnboardingCategory',
  components: { SectionInformationBox, VuetifyToolBar },
  mixins: [onboardingMixin, uiMixin, intercomMixin],
  data() {
    return {
      id: this.$route.params.id || null // Menu ID
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  destroyed() {
    // Paramos escucha
    this.$root.$off('completed-category-form')
  },
  methods: {
    ...mapMutations('authentication', ['setOnboadingSection']),
    /**
     * Usuario pulsa en el botón de acción
     */
    handleClickButton() {
      this.showFormDialog()
    },
    /**
     * Obtenemos todo lo necesario para inicializar el componente
     */
    async getEveryNeededData() {
      // Capturamos evento
      this.$root.$on('completed-category-form', this.captureCategoryFormCompleted)

      if (!this.id) {
        // Marcamos paso como completado
        await this.setSectionCompleted()
      }
    },
    /**
     * Mostramos el diálogo del formulario
     */
    showFormDialog() {
      // Si no tiene numero o place le pedimos que lo complete
      this.modifyAppDialog({
        title: 'Crear Categoría',
        contentComponent: CategoryForm,
        contentComponentProps: { parentId: this.id },
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Tratamos el evento de completitud del formulario de categoría
     *
     * @param {object} data - datos del evento
     */
    async captureCategoryFormCompleted(data) {
      // Modificamos variable de Intercom
      this.intercomUpdate({
        bakarta_onboarding_category: true
      })
      // Cerramos modal
      this.modifyAppDialog({
        visible: false
      })
      // Seteamos parámetros en Store del onboarding
      this.setOnboadingSection({
        section: 'product',
        params: data
      })
      // Establecemos paso como completado
      await this.setSectionCompleted('category')
    }
  }
}
